import React from 'react'
import Header from '../header/Header'
import './hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import heart from "../../assets/heart.png"
import calories from '../../assets/calories.png'

import { motion } from 'framer-motion'

const Hero = () => {
    const transtion = {type: 'spring', duration: 3}
    const mobile = window.innerWidth<=786 ? true: false;


  return (
    <div className='hero'>
        <div className='left-h'>
           <Header />

           <div className='the-best-ad'>
            <motion.div 
            initial={{left: mobile? "168px" : '238px'}}
            whileInView={{left: '8px'}}
            transition={{...transtion, type: 'tween'}}
            >

            </motion.div>
            <span>the best fitness club in the town</span>

           </div>

           <div className='hero-text'>
            <div>
                <span className='stroke-text'>Shape</span> &nbsp;
                <span>Your</span>
            </div>
            <div>
                <span>Ideal body</span>
            </div>
            <div>
                <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
            </div>

           </div>


           <div className='figures'>
            <div>
                <span>+120</span>
                <span>expert coaches</span>
            </div>
            <div>
                <span>+310</span>
                <span>MEMBERS JOINED</span>
            </div>
            <div>
                <span>+40</span>
                <span>FITNESS PROGRAMS</span>
            </div>


           </div>

           <div className='hero-buttons'>
            <button className='btn'>Get Started</button>
            <button className='btn'>learn More</button>

           </div>


        </div>
        <div className='right-h'>
           <button className='btn'>
            Join Now

           </button>

           <motion.div
           inherit={{right: '-1rem'}}
           whileInView={{right: "4rem"}}
           transition={transtion}

            className='heart-rate'>
            <img src={heart} alt="" />
            <span>Heart Rate</span><span>116 bpm</span>

           </motion.div>

           <img src={hero_image} className="hero-image" alt="" />
           <motion.img 
           initial={{right: '11rem'}}
           whileInView={{right: '20rem'}}
           transition={transtion}
           src={hero_image_back}
           className="hero-image-back" alt="" />

           <motion.div 
            initial={{right: '12rem'}}
            whileInView={{right: '30rem'}}
            transition={transtion}

             className='calories'>
            <img src={calories}  alt="" />
            <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
            </div>

           </motion.div>

        </div>

    </div>
  )
}

export default Hero