import React, { useState } from 'react'
import './header.css'
import Logo from '../../assets/logo.png';
import bars from '../../assets/bars.png';
import {Link} from 'react-scroll'


const Header = () => {
  const mobile = window.innerWidth<=786 ? true: false;
  const [menuOpen, setMenu] = useState(false);



  return (
    <div className='header'>
        <img src={Logo} className="logo" alt="" />
        {menuOpen ===false && mobile === true ? (
          

          <div

          onClick={() => setMenu(true)}
          >
            <img src={bars}
            style={{width: '1.5rem', height: '1.5rem'}}
             alt="" />

          </div>
        ): (
          <ul className='header-menu'>
          <li>
            <Link
            onClick={() => setMenu(false)}
            to='hero'
            span={true}
            smooth={true}
            >Home</Link>
          </li>
          <li>
            <Link
            onClick={() => setMenu(false)}
            to='programs'
            span={true}
            smooth={true}
            >
            Programs
            </Link>
          </li>
          <li>
            <Link
            onClick={() => setMenu(false)}
            to='reasons'
            span={true}
            smooth={true}
            >
              Why us
            </Link>
            
          </li>
          <li>
            <Link
            to='plans'
            span={true}
            smooth={true}
            onClick={() => setMenu(false)}
            >
              Plans
            </Link>
            
          </li>
          <li >
            <Link
            onClick={() => setMenu(false)}
            to='testimonials'
            span={true}
            smooth={true} 
            >
            Testimonials
            </Link>
          </li>
      </ul>
        )}

       

    </div>
  )
}

export default Header