import React from 'react'
import './footer.css'
import github from '../../assets/github.png'
import insta from '../../assets/instagram.png'
import link from '../../assets/linkedin.png'
import logo from '../../assets/logo.png'

const Footer = () => {
  return (
    <div className='footer-container'>
        <hr />
        <div className='footer'>
            <div className='social-links'>
            <img src={github} alt="" />
            <img src={insta} alt="" />
            <img src={link} alt="" />
            </div>
         
        <div className='logo-f'>
            <img src={logo} alt="" />
        </div>
     </div>   


    </div>
  )
}

export default Footer