import React, { useState } from 'react'
import './testimonials.css'
import {testimonialsData} from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png';
import {motion} from 'framer-motion'



const Testimonials = () => {
    const transtion = {type: 'spring', duration: 3}


    const [select, setSelect] = useState(0);
    const tLenth = testimonialsData.length;




  return (
    <div className='testimonials'>
        <div className='left-t'>
            <span>TESTIMONIALS</span>
            <span className='stroke-text'>WHAT THEY</span>
            <span>SAY ABOUT US</span>
            <motion.span
             key={select}
             initial={{opacity:0, x: -100}}
             animate={{opacity: 1, x:0}}
             exit={{opacity:0, x: 100}}
             transition={transtion}
            >
                {testimonialsData[select].review}
            </motion.span>
            <span>
                <span style={{color: 'var(--orange)'}}>
                    {testimonialsData[select].name}
                </span> &nbsp;
                 - {testimonialsData[select].status}
            </span>

        </div>
        <div className='right-t'>
            <motion.div
            initial={{opacity: 0, x: -100}}
            transition={{...transtion, duration: 2}}
            whileInView={{opacity: 1, x: 0}}
            >

            </motion.div>
            <motion.div
            initial={{opacity: 0, x: 100}}
            transition={{...transtion, duration: 2}}
            whileInView={{opacity: 1, x: 0}}
            >

            </motion.div>
            <motion.img 
            key={select}
            initial={{opacity:0, x: 100}}
            animate={{opacity: 1, x:0}}
            exit={{opacity:0, x: -100}}
            transition={transtion}
            src={testimonialsData[select].image}
             alt="" />
            <div className='arrows'>
                <img 
                onClick={() => {
                    select===0?setSelect(tLenth -1):
                    setSelect((prev) => prev -1)
                }}
                
                src={leftArrow} alt="" />
                <img
                onClick={() => {
                    select=== tLenth - 1 ?setSelect(0):
                    setSelect((prev) => prev +1)
                }}
                
                src={rightArrow} alt="" />

            </div>
        </div>

    </div>
  )
}

export default Testimonials